import { JwtInterface } from '@src/lib/jwt/types';

export enum UsersActions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

export interface UsersAction {
  type: UsersActions;
  payload: string;
}

export interface UsersContextInterface {
  user: JwtInterface;
  token: string;
}
