import React from 'react';
import { Table, Spinner, Button } from 'react-bootstrap';
import { ProductsTabProps } from './types';
import { resyncProduct } from '@src/Api';

export const ProductsTab: React.FC<ProductsTabProps> = ({ productSync, loading, setToasts, fetchLogsSync }) => {
  const [activeProductIds, setActiveProductIds] = React.useState<number[]>([]);

  const handleResyncClick = async (rentmanId: number): Promise<void> => {
    setActiveProductIds((prevActiveProductIds) => [...prevActiveProductIds, rentmanId]);

    const response = await resyncProduct(rentmanId);

    if (response?.status === 200) {
      const message = response.data.message || response.data.hint;
      setToasts((prevToasts) => [...prevToasts, { id: rentmanId, message }]);
      fetchLogsSync();
    }

    setActiveProductIds((prevActiveProductIds) => prevActiveProductIds.filter((id) => id !== rentmanId));
  };

  return (
    <Table striped responsive>
      <thead>
        <tr>
          <th>Timestamp</th>
          <th>Request ID</th>
          <th>Product ID</th>
          <th>Product SKU</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={5} className="table-loader">
              <Spinner className="data-loading-spinner"></Spinner>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {productSync.map((sync, index) => (
            <tr key={index}>
              <td>{new Date(sync.product_log_timestamp).toLocaleString('da-DK')}</td>
              <td>{sync.request_id}</td>
              <td>{sync.product_log_product_id}</td>
              <td>{sync.product_log_product_sku && sync.product_log_product_sku !== '0' ? sync.product_log_product_sku : null}</td>
              <td>{sync.product_log_status}</td>
              <td>
                {activeProductIds.includes(sync.product_log_product_id) ? (
                  <Spinner className="custom-spinner" animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Button onClick={() => handleResyncClick(sync.product_log_product_id)}>Re-sync</Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </Table>
  );
};
